<template>
    <template v-if="loaded && job.status != 'pending'">
    <template v-for="booking in job.bookings" :key="booking.id">
    <h4 class="mt-4">{{getBookingTabName(booking)}}</h4>
    <template v-for="(unit, index) in booking.job_unit" :key="unit.id">
        <div class="accordion" :id="'a' + booking.id + '-accordion'">
            <div class="accordion-item">
                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button class="acc-header-item header-main accordion-button no-delete" type="button" data-bs-toggle="collapse" :data-bs-target="'#a'+booking.id+'-collapse-'+index" 
                            aria-expanded="false" :aria-controls="'#a'+booking.id+'-collapse-'+index"  @click="getImages(unit)" :class="{'collapsed': detailToOpen != unit.id}" :id="'ub-'+unit.id">
                        <div class="d-flex w-100">
                            <div class="col-1" style="width: 10%">
                                {{unit.make.name}}
                            </div>
                            <div class="col-1" style="width: 10%">
                                {{unit.model.name}}
                            </div>
                            <div class="col-1 license-plate" style="width: 16%">
                                {{unit.chassis_number}}
                            </div>
                            <div class="col-1" style="width: 20%">
                                {{(unit.booked_measurement.length + ' x ' + unit.booked_measurement.width + ' x ' + unit.booked_measurement.height + ' cm; ' + unit.booked_measurement.weight + ' kg')}}
                            </div>
                            <div class="col-1" style="width: 12%">
                                <i :class="'fa ' + checkDelivered(unit)"></i>&ensp;{{ $t('operations.date_delivered') }}
                            </div>
                            <div class="col-1" style="width: 12%">
                                <i :class="'fa ' + checkMeasured(unit)"></i>&ensp;{{ $t('operations.date_measured') }}
                            </div>
                            <div class="col-1" style="width: 12%">
                                <i :class="'fa ' + checkLoaded(unit)"></i>&ensp;{{ $t('operations.date_loaded') }}
                            </div>
                            <div class="col-1" style="width: 8%" v-if="unit.file_info.length || unit.files.length">
                                {{(unit.files.length + unit.file_info.length) + ' ' + $tc('form.pictures', unit.files.length + unit.file_info.length).toLowerCase()}}
                            </div>
                        </div>
                    </button>
                    <div></div>
                    <button type="button" class="acc-header-item header-arrow" @click="$event.target.previousElementSibling.click()"></button>
                </div>

                <div class="accordion-collapse collapse" :class="{'show': detailToOpen == unit.id}" :id="'a' + booking.id + '-collapse-'+index" :aria-labelledby="'heading-'+index" :data-bs-parent="'#a'+booking.id+'-accordion'">
                    <div class="accordion-body">
                        <div class="fw-bold mb-1">{{$t('operations.unit_details')}}</div>
                        <div class="row">
                            <label class="col-sm-2 col-form-label-sm">
                                <div>{{$tc('navigation.commodities', 1)}}</div>
                                <div class="form-select form-select-sm text-ellipsis disabled">{{unit.commodity.name}}</div>
                            </label>
                            <label class="col-sm-1 col-form-label-sm">
                                <div>{{$t('form.new')}}</div>
                                <FormItem v-model="unit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
                            </label>
                            <div class="col-sm-1p5 col-form-label-sm">
                                <div>{{$t('navigation.makes')}}</div>
                                <div class="form-select form-select-sm text-ellipsis disabled">{{unit.make.name}}</div>
                                <div class="col-form-label-sm">{{ $t('sales.build_year') }}</div>
                                <FormItem v-model="unit.construction_year" type="number" />
                            </div>
                            <div class="col-sm-1p5 col-form-label-sm">
                                <div>{{$t('navigation.models')}}</div>
                                <div class="form-select form-select-sm text-ellipsis disabled">{{unit.model.name}}</div>
                                <div class="col-form-label-sm"> {{ $t('navigation.colours') }}</div>
                                <FormItem v-model="unit.colour_id" type="select" :options="colours" />
                            </div>
                            <div class="col-sm-2 col-form-label-sm">
                                <div>{{ $t('sales.vin') }}</div>
                                <input v-model="unit.chassis_number" class="form-control form-control-sm license-plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target)" />
                                <div class="col-form-label-sm">{{ $t('navigation.hs_codes') }}</div>
                                <FormItem v-model="unit.hs_code" type="text" />
                            </div>
                            <div class="col-sm-2 col-form-label-sm">
                                <template v-if="licensePlateRequired">
                                    <div>{{ $t('sales.license_plate') }}</div>
                                    <input v-model="unit.license_plate" class="form-control form-control-sm license-plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target)" />
                                </template>
                                <div v-else style="height: 47px"></div>
                                <div class="col-form-label-sm">{{$t('operations.date_delivered')}}</div>
                                <FormItem type="datepicker" v-model="unit.delivered_date" />
                            </div>
                        </div>
                        <div class="border-left">
                            <div class="row" v-for="sunit in unit.stacked_units" :key="sunit.id">
                                <label class="col-sm-auto col-form-label-sm" style="width: 15.7%">
                                    <div>{{$tc('navigation.commodities', 1)}}</div>
                                    <div class="form-select form-select-sm text-ellipsis disabled">{{sunit.commodity.name}}</div>
                                </label>
                                <label class="col-sm-1 col-form-label-sm">
                                    <div>{{$t('form.new')}}</div>
                                    <FormItem v-model="sunit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm">
                                    <div>{{$t('navigation.makes')}}</div>
                                    <div class="form-select form-select-sm text-ellipsis disabled">{{sunit.make.name}}</div>
                                    <div class="col-form-label-sm">{{ $t('sales.build_year') }}</div>
                                    <FormItem v-model="sunit.construction_year" type="number" />
                                </label>
                                <label class="col-sm-auto col-form-label-sm" style="width: 11.5%">
                                    <div>{{$t('navigation.models')}}</div>
                                    <div class="form-select form-select-sm text-ellipsis disabled">{{sunit.model.name}}</div>
                                    <div class="col-form-label-sm"> {{ $t('navigation.colours') }}</div>
                                    <FormItem v-model="sunit.colour_id" type="select" :options="colours" />
                                </label>
                                <label class="col-sm-2 col-form-label-sm">
                                    <template v-if="licensePlateRequired">
                                        <div>{{ $t('sales.license_plate') }}</div>
                                        <input v-model="sunit.license_plate" class="form-control form-control-sm license-plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target)" />
                                    </template>
                                    <div v-else style="height: 47px"></div>
                                    <div class="col-form-label-sm">{{ $t('navigation.hs_codes') }}</div>
                                    <FormItem v-model="sunit.hs_code" type="text" />
                                </label>
                            </div>
                        </div>

                        <template v-for="(item, itemindex) in unit.job_booking_voyage_job_units" :key="itemindex">
                            <hr>
                            <h5>{{getBookingTabName(booking, true, true, itemindex, item.job_booking_voyage_id)}}</h5>
                            <div class="row mt-3">
                                <label class="col-sm-3 col-form-label-sm">
                                    <div class="fw-bold fs-6">{{$t('operations.dimensions')}}</div>
                                    <div class="mt-1">{{$t('operations.dimensions_booked')}}</div>
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm">
                                    {{$t('form.length')}}<br>
                                    <FormItem v-model="unit.booked_measurement.length" type="number-with-tags" endTag="cm" :disabled="job.office_id && job.office_id != currentOffice" @onchange="unit.altBooked = true" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm">
                                    {{$t('form.width')}}<br>
                                    <FormItem v-model="unit.booked_measurement.width" type="number-with-tags" endTag="cm" :disabled="job.office_id && job.office_id != currentOffice" @onchange="unit.altBooked = true" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm">
                                    {{$t('form.height')}}<br>
                                    <FormItem v-model="unit.booked_measurement.height" type="number-with-tags" endTag="cm" :disabled="job.office_id && job.office_id != currentOffice" @onchange="unit.altBooked = true" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm">
                                    {{$t('form.weight')}}<br>
                                    <FormItem v-model="unit.booked_measurement.weight" type="number-with-tags" endTag="kg" :disabled="job.office_id && job.office_id != currentOffice" @onchange="unit.altBooked = true" />
                                </label>
                            </div>
                            <div class="row">
                                <label class="col-sm-3 col-form-label-sm">
                                    <div class="pt-1">{{$t('operations.dimensions_measured')}}</div>
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm pt-0">
                                    <FormItem v-model="item.measured_measurement.length" type="number-with-tags" endTag="cm" class="mt-1" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm pt-0">
                                    <FormItem v-model="item.measured_measurement.width" type="number-with-tags" endTag="cm" class="mt-1" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm pt-0">
                                    <FormItem v-model="item.measured_measurement.height" type="number-with-tags" endTag="cm" class="mt-1" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm pt-0">
                                    <FormItem v-model="item.measured_measurement.weight" type="number-with-tags" endTag="kg" class="mt-1" />
                                </label>
                            </div>
                            <div class="fw-bold mt-3 mb-1">{{$tc('form.reference', 2)}}</div>
                            <div class="row">
                                <label class="col-sm-3 col-form-label-sm">
                                    {{$t('operations.ref_customer')}}<br>
                                    <FormItem v-model="unit.customer_reference" />
                                </label>
                                <label class="col-sm-2 col-form-label-sm">
                                    {{$t('operations.ref_carrier')}}<br>
                                    <FormItem v-model="item.carrier_reference" />
                                </label>
                                <label class="col-sm-2 col-form-label-sm">
                                    {{$t('operations.ref_port')}}<br>
                                    <FormItem v-model="item.port_reference" />
                                </label>
                                <label class="col-sm-2 col-form-label-sm">
                                    {{$t('operations.ref_customs')}}<br>
                                    <FormItem v-model="item.customs_reference" />
                                </label>
                                <label class="col-sm-1 col-form-label-sm">
                                    <br>
                                    <FormItem v-model="item.customs_type_id" type="select" :options="customsRefOptions" />
                                </label>
                                <label class="col-sm-2 col-form-label-sm">
                                    {{$t('overview.location')}}<br>
                                    <FormItem v-model="item.location" />
                                </label>
                            </div>
                            <div class="row mt-3">
                                <label class="col-sm-1 col-form-label-sm pe-0">
                                    <span class="fw-bold fs-6">{{$t('overview.status')}}</span><br>
                                    <FormItem :id="'clear-' + item.id" :label="$t('operations.status_cleared')" v-model="item.is_cleared" type="checkbox" />
                                </label>
                                <label class="col-sm-1p5 col-form-label-sm mt-4 pe-0">
                                    <FormItem :id="'aoe-' + item.id" :label="$t('operations.arrival_on_exit')" v-model="item.is_arrival_on_exit" type="checkbox" />
                                </label>
                                <label class="col-sm-1 col-form-label-sm mt-4 pe-0">
                                    <FormItem :id="'surv-' + item.id" :label="$t('operations.status_surveyed')" v-model="item.is_surveyed" type="checkbox" />
                                </label>
                                <label class="col-0p9 col-form-label-sm mt-4 pe-0">
                                    <FormItem :id="'hold-' + item.id" :label="$t('operations.status_on_hold')" v-model="item.is_on_hold" type="checkbox" />
                                </label>
                                <label class="col-0p9 col-form-label-sm mt-4 pe-0">
                                    <FormItem :id="'prio-' + item.id" :label="$t('operations.status_priority')" v-model="item.is_priority" type="checkbox" />
                                </label>
                                <label class="col-sm-2 col-form-label-sm">
                                    {{$t('operations.date_loaded')}}<br>
                                    <FormItem type="datepicker" v-model="item.loaded_date" />
                                </label>
                            </div>
                        </template>
                        <hr>

                        <div class="fw-bold mt-3 mb-1">{{ $tc('form.remark', 2) }}</div>
                        <div class="row">
                            <FormItem type="textarea" v-model="unit.job_unit_message" style="width: 58.3%" />
                        </div>
                        <template v-for="remark in unit.remarks" :key="remark.id">
                            <div class="fst-italic mt-1"> {{ formatDate(remark.created_at, 'dateTimeNoSeconds') }} - {{ remark.user.name }} </div>
                            <div> <pre>{{ remark.content }}</pre> </div>
                        </template>
                        <div class="fw-bold mt-3 mb-1">{{$tc('form.pictures', 2) + ' (' + (unit.files.length + unit.file_info.length) + ')'}}</div>
                        <div class="d-flex">
                            <div class="d-flex w-75">
                                <input type="file" accept="image/*" multiple="" class="d-none" @change="uploadDragDropFile($event, unit, true)" />
                                <div class="image-drop" @dragover="dropzoneDragOver" @dragleave="dropzoneDragLeave" @drop="uploadDragDropFile($event, unit)" @click="$event.target.previousElementSibling.click()">
                                    {{$t('form.drop_images')}}
                                </div>
                                <div v-for="image, imageIndex in unit.files" :key="imageIndex" class="uploaded-image" :style="{backgroundImage: 'url(' + image.url + ')'}">
                                    <i class="fa fa-close" @click="deleteUploadedImage(unit, imageIndex)"></i>
                                </div>
                                <div v-for="image, imageIndex in unit.file_info" :key="imageIndex" class="uploaded-image" :style="{backgroundImage: 'url(' + image.url + ')'}">
                                    <i class="fa fa-close" @click="unit.file_info.splice(imageIndex, 1)"></i>
                                </div>
                            </div>
                            <div class="d-flex w-25 justify-content-end">
                                <button class="btn btn-success h-50 align-self-end px-4" type="button" @click="updateUnits($event, unit, index)">
                                    {{ $t('form.save') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    </template>
    </template>
</template>

<script>
    import moment from 'moment';
    import store from '@/store/user';
    import dataStore from '@/store/data';
    import jobUnitService from '@/services/JobUnitService';
    import fileService from "@/services/FileService";

    export default {
        props: ['jobItem', 'colours', 'hscodes', 'isEditing', 'loaded'],
        name: 'JobUnitDetailsForm',
        computed: {
            job: {
                get() {
                    return this.jobItem
                }
            },
            licensePlateRequired() { return this.jobItem.port_of_loading.country.license_plate_required_export || this.jobItem.port_of_discharge.country.license_plate_required_import }
        },
        data() {
            return {
                detailToOpen: null,
                dropHighlightColor: 'blue',
                dropDefaultColor: '#888',
                customsRefOptions: [],
                currentOffice: null
            }
        },
        methods: {
            getBookingTabName(booking, longName = false, unitDetailsName = false, voyageId = 0, jobBookingVoyageId = 0){
                let voyage = booking.voyages[voyageId];
                voyage = voyage ? voyage : (booking.voyages[0] ? booking.voyages[0] : {vessel: {}});
                if(longName){
                    const nameheader = this.$t('operations.booking') + ' ' + booking.number;
                    const vesselheader = voyage.vessel.name + ' ' + voyage.number;
                    if(unitDetailsName){
                        const jbv = booking.job_booking_voyage.find(voy => voy.id == jobBookingVoyageId);
                        let pol = jbv && jbv.port_of_loading ? jbv.port_of_loading.code : this.job.port_of_loading.code;
                        let pod = jbv && jbv.port_of_discharge ? jbv.port_of_discharge.code : this.job.port_of_discharge.code;
                        return vesselheader + ', ' + pol + ' - ' + pod;
                    }
                    return nameheader + ": " + booking.job_unit.length + ' units, ' + vesselheader + ', (' + this.job.carriers.find(carr => carr.id = voyage.carrier_id).relation.name + '), ' + 
                           this.job.port_of_loading.code + ' > ' + this.job.port_of_discharge.code;
                }
                return booking.number + ' (' + booking.job_unit.length + ' units) ';
            },
            dropzoneDragOver(e){
                e.preventDefault();
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropHighlightColor;
                    e.target.parentElement.style.borderColor = this.dropHighlightColor;
                }
                else{
                    e.target.style.borderColor = this.dropHighlightColor;
                    e.target.style.color = this.dropHighlightColor;
                }
                
            },
            dropzoneDragLeave(e){
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropDefaultColor;
                    e.target.parentElement.style.borderColor = this.dropDefaultColor;
                }
                else{
                    e.target.style.borderColor = this.dropDefaultColor;
                    e.target.style.color = this.dropDefaultColor;
                }
            },
            async uploadDragDropFile(e, item, isOnClick = false){
                e.preventDefault();
                if(!isOnClick){
                    this.dropzoneDragLeave(e);
                }
                if (isOnClick || e.dataTransfer.items) {
                    let files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
                    const length = files.length + 1 - 1;
                    
                    for (var i = 0; i < length; i++) {
                        let file = files[i];

                        let fileSize = Math.ceil(file.size / 1024) + ' kb';
                        let fileType = file.type.split('/')[1];
                        if(file.name.match(/\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/)){
                            item.job_unit.file_info.push({
                                'type': fileType,
                                'name': file.name.replace(/[^a-z0-9.]/gi, '_'),
                                'size': fileSize,
                                'date': moment(),
                                'user': store.getters.getUser,
                                'blob': await this.blobToBase64(file),
                                'url': window.URL.createObjectURL(file)
                            });
                        }
                    }
                }
                if (isOnClick){
                    e.target.value = "";
                }
            },
            async blobToBase64(blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.substr(reader.result.indexOf(',')+1));
                    reader.readAsDataURL(blob);
                });
            },
            deleteUploadedImage(item, imageIndex){
                if(item.delete_images === undefined){
                    item.delete_images = [];
                }
                item.delete_images.push(item.files[imageIndex].id);
                item.files.splice(imageIndex, 1);
            },
            updateUnits(e, units){
                e.target.disabled = true;
                console.log(units);
                window.setTimeout( () => { this.$nextTick( () => {
                    jobUnitService.update(units).then((response) => {
                        e.target.disabled = false;
                        if(response.data.files){
                            const fileStart = response.data.files.length - units.file_info.length;
                            const fileEnd = response.data.files.length;
                            for(let i = fileStart; i < fileEnd; i++){
                                units.files.push({
                                    url: units.file_info[i - fileStart].url
                                });
                            }
                        }
                        units.file_info = [];
                        units.delete_images = [];
                        if(units.job_unit_message){
                            units.remarks.splice(0, 0, {
                                id: 0,
                                content: units.job_unit_message,
                                created_at: moment(),
                                user: {
                                    id: 0,
                                    name: store.getters.getName
                                }
                            });
                            units.job_unit_message = null;
                        }
                        response.data.job_booking_voyage_job_units.forEach(jbvju => {
                            const thisunit = units.job_booking_voyage_job_units.find(ujbvju => ujbvju.id == jbvju.id);
                            if(thisunit) thisunit.measured_unit_measurement_id = jbvju.measured_unit_measurement_id;
                        })
                        function setData(item, data, measurements = null){
                            if(item.job_booking_voyage_job_units){
                                data.job_booking_voyage_job_units.forEach(jbvju => {
                                    const thisunit = item.job_booking_voyage_job_units.find(ujbvju => ujbvju.id == jbvju.id);
                                    if(thisunit) thisunit.measured_unit_measurement_id = jbvju.measured_unit_measurement_id;
                                });
                            }
                            else{
                                item.measured_unit_measurement_id = data.job_booking_voyage_job_units[0].measured_unit_measurement_id;
                            }
                            item.construction_year = data.construction_year;
                            item.colour_id = data.colour_id;
                            item.hs_code = data.hs_code;
                            item.license_plate = data.license_plate;
                            item.chassis_number = data.chassis_number;
                            if(measurements){
                                item.weight = measurements.weight;
                                item.volume = (measurements.length * measurements.width * measurements.height) / 1000000;
                            }
                        }
                        const booking = this.job.bookings.find(booking => booking.id = response.data.job_booking_id);
                        const pendingUnit = booking.pending_units.find(punit => punit.id == response.data.id);
                        if(pendingUnit) setData(pendingUnit, response.data);
                        const billoflading = booking.bills_of_lading.find(bl => bl.id == response.data.bill_of_lading_id);
                        if(billoflading){
                            const jobUnit = billoflading.job_units.find(punit => punit.id == response.data.id);
                            if(jobUnit) setData(jobUnit, response.data, units.altBooked ? units.booked_measurement : null);
                            delete units.altBooked;
                        }
                        if(billoflading && response.data.job_unit_container_id){
                            const container = billoflading.job_unit_containers.find(punit => punit.id == response.data.job_unit_container_id);
                            const containerUnit = container.job_units.find(punit => punit.id == response.data.id);
                            if(containerUnit) setData(containerUnit, response.data);
                        }
                        this.$toast.success(this.$t('form.updated_x', [this.$t('navigation.units')]));
                    }).catch(error => {
                        e.target.disabled = false;
                        this.toastError(error)
                        console.log('error', error)
                    });
                }, 1000)});
            },
            getImages(item){
                if(item.imagesLoaded) return;
                fileService.getImages(item.id).then(response => {
                    response.data.forEach((image, imageIndex) => {
                        item.files[imageIndex].url = ('"' + image + '"');
                    })
                    item.imagesLoaded = true;
                    this.$forceUpdate();
                }).catch(error => {
                    this.toastError(error)
                })
            },
            checkDelivered(unit){
                let delivered = true;
                if(!unit.delivered_date || new Date(unit.delivered_date).getTime() > new Date().getTime()){
                    delivered = false;
                }
                return delivered ? 'fa-check' : 'fa-xmark';
            },
            checkMeasured(unit){
                let measured = true;
                for (let item of unit.job_booking_voyage_job_units) {
                    if(!item.measured_measurement.length || !item.measured_measurement.height || !item.measured_measurement.width || !item.measured_measurement.weight){
                        measured = false;
                        break;
                    }
                }
                return measured ? 'fa-check' : 'fa-xmark';
            },
            checkLoaded(unit){
                let loaded = true;
                for (let item of unit.job_booking_voyage_job_units) {
                    if(!item.loaded_date || new Date(item.loaded_date).getTime() > new Date().getTime()){
                        loaded = false;
                        break;
                    }
                }
                return loaded ? 'fa-check' : 'fa-xmark';
            },
        },
        mounted(){
            this.customsRefOptions = dataStore.getters.getCustomTypes;
            this.currentOffice = store.getters.getCompanyId;
        }
    }
</script>

<style scoped>
    .col-sm-1p5 {flex: 0 0 auto; width: 11%}
    .col-0p9 {flex: 0 0 auto; width: 7%}
    .is-invalid ~ .invalid-feedback{
        position: absolute;
        width: 300px;
        margin-top: 34px;
    }
    .accordion-item {
        margin-bottom: 1rem;
        border: 2px solid #0d6efd;
        border-radius: 6px
    }
    .acc-header-item { border: none }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        width: calc(100% - 100px);
        box-shadow: none !important;
        border-radius: 0px !important;
        transition: background-color .15s
    }
    .acc-header-item.header-main.no-delete {
        width: calc(100% - 50px) !important;
    }
    .acc-header-item.header-remove-item, .acc-header-item.header-arrow {
        width: 50px;
        transition: background-color .15s
    }
    .accordion-button:not(.collapsed), .accordion-button:not(.collapsed) + .header-remove-item, .accordion-button:not(.collapsed) + * + .header-arrow {
        background-color: #0d6efd;
        color: white
    }
    .accordion-button.collapsed, .accordion-button.collapsed + .header-remove-item, .acc-header-item.collapsed + * + .header-arrow {
        background-color: white;
        color: #0d6efd;
        font-weight: bold;
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .accordion-button:not(.collapsed) + * + .acc-header-item.header-arrow::after {
        rotate: 90deg
    }
    .header-main > div > div {
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 10px
    }
    .description-toggle{
        width: auto;
        padding: 2px 4px
    }
    .image-drop, .uploaded-image{
        width: 120px;
        height: 80px;
    }
    .image-drop{
        border: 3px dashed #888;
        color: #888;
        display: flex;
        text-align: center;
        align-items: center
    }
    .image-drop:hover{
        border-color: #0d6efd;
        color: #0d6efd;
        cursor: pointer
    }
    .uploaded-image{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 0.5rem;
        border: 1px solid black;
        text-align: end
    }
    .uploaded-image > i{
        background-color: rgba(250, 250, 250, 0.5);
        width: 20px;
        padding: 4px;
    }
    .uploaded-image > i:hover{
        color: red;
        cursor: pointer
    }
    #details .fa-xmark, #details .fa-check{
        position: relative;
        font-size: 20px;
        top: 2px;
        width: 1em
    }
    .disabled{
        background-color: #e9ecef
    }
    pre{
        font-family: var(--bs-body-font-family);
        line-height: 1.5rem;
        padding-left: 4px
    }
    .border-left{
        border-left: 4px solid gray;
        padding-left: 1%;
    }
</style>
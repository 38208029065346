<template v-if="formActive">
    <div v-if="job.carriers && !isEditing && !isCreatingNew && job.status != 'pending'" class="d-flex justify-content-between">
        <p>{{ getSubheader() }}</p>
    </div>
    <!-- Create new -->
    <div v-show="!isEditing">
        <JobCreateBookingForm ref="bookingCreate" :jobItem="job" :colours="colours" :commodities="commodities" :hscodes="hscodes" :ports="ports" :customers="customers" :commoditiesById="commoditiesById" :isCreatingNew="isCreatingNew" />
    </div>

    <!--  EDITING -->
    <div v-show="isEditing && !isCreatingNew">
        <div class="row" v-if="isEditing && !isCreatingNew">
            <ul class="nav nav-main nav-tabs mb-3 px-2" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="bookunit-tab" data-bs-toggle="tab" data-bs-target="#bookunit" type="button" role="tab" aria-controls="bookunit" aria-selected="true" @click="$refs.files.setDisabled(false)">
                        {{$t('operations.bookings_unit')}} <span v-show="job.bookings.length" class="badge">{{job.bookings.length}}</span>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" @click="$refs.files.setDisabled(true)">{{$t('operations.unit_details')}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="bldetails-tab" data-bs-toggle="tab" data-bs-target="#bldetails" type="button" role="tab" aria-controls="bldetails" @click="setUnitsModalOpen = false; $refs.files.setDisabled(false)">
                        {{$t('operations.bl_details')}} <span v-show="blCount" class="badge">{{blCount}}</span>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="quotations-tab" data-bs-toggle="tab" data-bs-target="#quotations" type="button" role="tab" aria-controls="quotations" @click="$refs.files.setDisabled(false)">
                        {{$tc('operations.additional_quotations', 2)}} <span v-show="aqCount" class="badge">{{aqCount}}</span>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="invoices-tab" data-bs-toggle="tab" data-bs-target="#invoices" type="button" role="tab" aria-controls="invoices" 
                           @click="$refs.proforma.$forceUpdate(); $refs.proforma.invoiceOnChange(); $refs.files.setDisabled(false)">{{$t('operations.pf_invoices')}}</button>
                </li>
            </ul>
        </div>
        <div class="row tab-content p-3" id="myTabContent">

            <!-- Bookings  -->
            <div class="tab-pane fade show active" id="bookunit" role="tabpanel" aria-labelledby="bookunit-tab" v-if="isEditing">
                <JobBookingForm ref="jobbooking" :jobItem="job" :isEditing="isEditing" />
            </div>

            <!-- Bills of Lading  -->
            <div class="tab-pane fade" id="bldetails" role="tabpanel" aria-labelledby="bldetails-tab">
                <div class="row">
                    <div class="col-2"> <p>{{ $t('operations.bl_booking') }}:</p> </div>
                    <div class="col-10">
                        <select class="form-select form-select-sm w-25" v-model="selectedBooking" @click="setUnitsModalOpen = false" @change="resetBLComponents">
                            <option v-for="booking, index in job.bookings" :key="index" :value="index">{{ getBookingTabName(booking) }}</option>
                        </select>
                    </div>
                </div>
                <div class="row" v-if="isEditing">
                    <div class="col-2 pe-0 mt-2">
                        <ul class="nav nav-tabs mb-3 flex-column align-items-end" id="myTab2" role="tablist" @click="setUnitsModalOpen = false">
                            <li class="nav-item my-1" role="presentation" v-for="billOflading, index in job.bookings[selectedBooking].bills_of_lading" :key="index">
                                <button :class="'nav-link-alt ' + (index == 0 ? 'active' : '')" :id="'a'+index+'-tab'" data-bs-toggle="tab" :data-bs-target="'#b'+index" type="button" 
                                        role="tab" :aria-controls="'b'+index" aria-selected="true">{{billOflading.number.replaceAll('.', '')}}
                                        <i v-if="billOflading.id" class="fa fa-close text-light px-2" @mousedown="deleteBL($event, job.bookings[selectedBooking], index, billOflading)"></i>
                                        <i v-else class="px-3"></i>
                                </button>
                            </li>
                            <li class="w-100 p-4" v-show="canCreateBL()">
                                <button class="btn btn-primary w-100" type="button" @click="addNewBL(job.bookings[selectedBooking])"><span class="fa fa-plus"></span> &nbsp;{{$t('form.add', ['B/L'])}}</button>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content col-10 border border-dark rounded" id="myTabContent2">
                        <div v-for="billOflading, index in job.bookings[selectedBooking].bills_of_lading" :key="index" :class="'tab-pane fade' + (index == 0 ? ' show active' : '')" :id="'b'+index" role="tabpanel" :aria-labelledby="'a'+index+'-tab'">
                            <JobBillOfLadingComponent :jobItem="job" :colours="colours" :hscodes="hscodes" :currencies="currencies" :countries="countries" :index="index" :isManualResetting="isResetting"
                                                     :surveyers="surveyers" :containerTypes="containerTypes" :selectedBooking="selectedBooking" :blItem="billOflading" :loaded="loaded"
                                                     :loadingCountry="job.port_of_loading.country" :dischargeCountry="job.port_of_discharge.country" :pendingUnits="job.bookings[selectedBooking].pending_units" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Additional Quotations -->
            <div class="tab-pane fade" id="quotations" role="tabpanel" aria-labelledby="quotations-tab">
                <JobAdditionalQuotationsForm ref="addquote" :jobItem="job" :currencies="currencies" :suppliers="suppliers" :quoteDescriptions="quoteDescriptions" :isEditing="isEditing" />
            </div>

            <!-- Pro Forma Invoice -->
            <div class="tab-pane fade" id="invoices" role="tabpanel" aria-labelledby="invoices-tab" v-show="setInvoice">
                <JobProFormaInvoiceForm ref="proforma" :jobItem="job" :suppliers="suppliers" :quoteDescriptions="quoteDescriptions" :currencies="currencies" :currenciesById="currenciesById" :isEditing="isEditing" />
            </div>

            <!-- Unit details -->
            <div class="tab-pane fade" id="details" role="tabpanel" aria-labelledby="details-tab" @dragover.prevent="" @drop.prevent="">
                <JobUnitDetailsForm ref="unitdetails" :jobItem="job" :colours="colours" :hscodes="hscodes" :isEditing="isEditing" :loaded="loaded" />
            </div>

            <Files :files="job.file_info.concat(job.quote.file_info)" @addFile="addFiles" tabClass=".nav-main" parentItemName="job" ref="files" v-if="isEditing" />
            <ContactHistory :id="job.id" ref="contactHistory" tabClass=".nav-main" v-if="isEditing" />
        </div>
        <ChangeVoyagePopup :changeVoyageIsOpen="changeVoyageIsOpen" ref="changeVoyagePopup" />
    </div>

</template>

<script>
    import Files from '@/components/formpopup/Files.vue';
    import ContactHistory from '@/components/formpopup/ContactHistory.vue';
    import JobCreateBookingForm from '@/components/jobs/JobCreateBookingForm.vue';
    import JobBookingForm from '@/components/jobs/JobBookingForm.vue';
    import JobAdditionalQuotationsForm from '@/components/jobs/JobAdditionalQuotationsForm.vue';
    import JobProFormaInvoiceForm from '@/components/jobs/JobProFormaInvoiceForm.vue';
    import JobUnitDetailsForm from '@/components/jobs/JobUnitDetailsForm.vue';
    import JobBillOfLadingComponent from '@/components/jobs/BillOfLadingComponentNew.vue';
    import ChangeVoyagePopup from '@/components/voyage/VoyagePlanningChangeVoyagePopup.vue';
    import relationService from "@/services/RelationService";
    import portService from "@/services/PortService";
    import commodityService from "@/services/CommodityService";
    import currencyService from "@/services/CurrencyService";
    import surchargeService from "@/services/SurchargeService";
    import countryservice from "@/services/CountryService";
    import fileService from "@/services/FileService";
    import colourService from "@/services/ColourService";
    import hscodeService from "@/services/HscodeService";
    import quoteDecriptionService from "@/services/QuotationDescriptionService";
    import billofladingService from '@/services/BillOfLadingService';
    import dataStore from '@/store/data'
    import store from '@/store/user'
    import jobService from '@/services/JobService';
   
    export default {
        name: 'JobForm',
        props: ['itemData'],
        components: {
            Files,
            ContactHistory,
            JobCreateBookingForm,
            JobBillOfLadingComponent,
            JobAdditionalQuotationsForm,
            JobProFormaInvoiceForm,
            JobUnitDetailsForm,
            JobBookingForm,
            ChangeVoyagePopup
        },
        data() {
            return {
                job: { units: [], additional_quotes: [] },
                dragData: {bl: null, dragIndex: null, dropIndex: null, isDragging: false},
                aqCount: 0,
                blCount: 0,
                blCountWithUnsavedBills: 0,
                customers: [],
                carriers:[],
                carrier_ids:[],
                commodities: [],
                currencies:[],
                currenciesById:[],
                selectedBL: 0,
                conditions:[],
                ports: [],
                voyages: [],
                countries: [],
                surveyers: [],
                hscodes: [],
                colours: [],
                suppliers: [],
                containerTypes: [],
                quoteDescriptions: [],
                extraInvoiceLines: [],
                quoteInvoiceLines: [],
                dialogUnit: {},
                onPageDragOverEvent: null,
                licensePlateRequired: false,
                typeOptions: [{id: 1, name: this.$t('operations.original_bl')}, {id: 2, name: this.$t('operations.express_bl')}],
                paymentOptions: [{id: 'PREPAID', name: this.$t('operations.prepaid')}, {id: 'COLLECT', name: this.$t('operations.collect')}],
                submitted: false,
                loading: false,
                invalid: {},
                isEditing: false,
                isCreatingNew: false,
                selectedBooking: 0,
                surcharges: [],
                commoditiesById: {},
                bookingToOpen: null,
                BLToOpen: null,
                detailToOpen: null,
                setUnitsModalOpen: false,
                changeVoyageIsOpen: false,
                tempCurrency: null,
                setInvoice: false,
                margins: {},
                dropHighlightColor: 'blue',
                dropDefaultColor: '#888',
                unitCount: 0,
                totals: {'sub': {}, 'vat': {}, 'grand': '-', 'code': ''},
                statusOptions: [{id: "pending", name: this.$t('quote_status.pending')}, {id: "accepted", name: this.$t('quote_status.accepted')},{id: "declined", name: this.$t('quote_status.declined')}],
                costTypes: [{id: "fixed", name: this.$t('operations.cost_fixed')}, {id: "estimate", name: this.$t('operations.cost_estimate')},{id: "maximum", name: this.$t('operations.cost_maximum')}],
                calcUnits: [{id: 1, name: 'Unit'}, {id: 2, name: 'w/m'}, {id: 3, name: 'l/m'}, {id: 4, name: 'cbm'}, {id: 5, name: '%'}, {id: 6, name: 'Flat fee'}],
                invoiceLineTypes: [{id: 0, name: 'Transport'}, {id: 2, name: 'Sea fright'}, {id: 1, name: 'Surcharge'}, {id: 3, name: 'Add. cost'}, {id: 4, name: 'Add. quote'}],
                PCalcUnitFields: ['purchase_price', 'purchase_currency_id'],
                SCalcUnitFields: ['sales_price', 'sales_currency_id'],
                vatAmounts: [{id: 0, name: '0%'}],
                quantityOptions: [],
                conditionOptions: [],
                relationService: relationService,
                loaded: false,
                isResetting: false,
                dataItemsLoaded: 0,
                dataItemsToLoad: 8,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.job = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                return true;
            },
            getData() {
                let returndata = {...this.job}
                returndata.quote_general_costs = returndata.quote_general_costs.filter(row => row.checked)
                return returndata;
            },
            setData(data, continueCreatingBookings = false) {
                this.loaded = false;
                if(data) {
                    console.log(data);
                    this.isCreatingNew = false;
                    this.totals = {'sub': {}, 'vat': {}, 'grand': '-', 'code': ''};
                    this.job = data;
                    let units = data.units.filter(ql => ql.job_booking_id === null);
                    this.job.units = units;
                    this.$refs.bookingCreate.reset();
                    this.unitCount = 0;
                    for(let unit of this.job.units){
                        unit.checked = 1;
                        unit.measured = unit.booked_measurement && unit.booked_measurement.length && unit.booked_measurement.width && unit.booked_measurement.height && unit.booked_measurement.weight;
                        this.$refs.bookingCreate.setSelectedUnits(unit);
                    }
                    this.job.bookings.forEach((booking) => booking.pending_units = [] );

                    if(!data.quote){
                        this.job.quote = {
                            file_info: [],
                            quote_transport_lines: [],
                            quote_general_costs: [],
                            sales_manager: {office: {id: null}, name: null},
                            quote_lines: []
                        };
                    }
                    if(!data.carriers || data.carriers.length == 0){
                        this.job.carriers = [];
                        this.job.bookings.forEach((booking) => {
                            booking.voyages.forEach((voyage) => {
                                this.job.carriers.push({
                                    id: voyage.carrier.id,
                                    pivot: {carrier_id: voyage.carrier.id},
                                    relation: voyage.carrier.relation
                                });
                            });
                        });
                    }

                    this.isEditing = (continueCreatingBookings ? false : (data.bookings.length > 0)) && this.job.status != 'pending';
                    if(this.isEditing){
                        const countries = dataStore.getters.getCountryNames;
                        this.$nextTick(() => {
                            this.aqCount = 0;
                            this.blCount = 0;
                            this.blCountWithUnsavedBills = 0;
                            this.selectedBooking = 0;
                            const isHQ = store.getters.getCompanyHq ? true : false;
                            this.job.bookings.forEach((booking) => {
                                let billsOfLadingIds = [];
                                booking.bills_of_lading.forEach((bl) => {
                                    billsOfLadingIds.push(bl.id);
                                    bl.amount_collect_currency_id = bl.amount_collect_currency_id ? bl.amount_collect_currency_id : window.defaultCurrency;
                                    bl.has_second_notify = bl.second_notify !== null ? 1 : 0;
                                    bl.showPaymentOptions = false;
                                    const portStatus = booking.voyages[0] && booking.voyages[0].voyage_port_statuses[0] ? booking.voyages[0] && booking.voyages[0].voyage_port_statuses[0] : {};
                                    bl.can_finalize = isHQ || bl.can_approve_finalize_own || bl.operated_by_office_id == store.getters.getCompanyId;
                                    bl.uneditable = portStatus.status != 'open' && !bl.can_finalize;
                                    bl.voyageClosed = portStatus.status != 'open';
                                    if(!bl.job_booking_voyage_manifest){
                                        bl.showPaymentOptions = true;
                                    }
                                    else{
                                        bl.job_booking_voyage_manifest.forEach(manifest => {
                                            if(manifest.is_nmt_bl) bl.showPaymentOptions = true;
                                        });
                                    }
                                });
                                let bookingUnits = booking.job_unit.filter(unit => !billsOfLadingIds.includes(unit.bill_of_lading_id));
                                booking.pending_units = this.hardCopy(bookingUnits);
                                booking.job_unit.forEach(unit => {
                                    unit.file_info = [];
                                    unit.job_unit_message = null;
                                    unit.remarks_string = "";
                                    unit.also_update_booking_unit = true;
                                    if(!unit.make) unit.make = {name: ''};
                                    if(!unit.model) unit.model = {name: ''};
                                    unit.remarks.forEach(remark => {
                                        unit.remarks_string += (remark.user.name + ":\n" + remark.content + "\n\n");
                                    });
                                    unit.remarks_string = unit.remarks_string.substring(0, unit.remarks_string.length - 2);
                                    this.unitCount += 1;
                                    unit.job_booking_voyage_job_units.forEach(jbvju => {
                                        unit.is_cleared = jbvju.is_cleared;
                                        unit['is_cleared_' + jbvju.job_booking_voyage_id] = jbvju.is_cleared;
                                        unit.is_on_hold = jbvju.is_on_hold;
                                        unit['is_on_hold_' + jbvju.job_booking_voyage_id] = jbvju.is_on_hold;
                                        unit.is_priority = jbvju.is_priority;
                                        unit['is_priority_' + jbvju.job_booking_voyage_id] = jbvju.is_priority;
                                        unit.is_surveyed = jbvju.is_surveyed;
                                        unit.job_booking_voyage_id = jbvju.id;
                                        unit['id_' + jbvju.job_booking_voyage_id] = jbvju.id;
                                        unit.loaded_date = jbvju.loaded_date;
                                        unit.status_id = jbvju.status_id;
                                        unit.measured_measurement = jbvju.measured_measurement;
                                        unit.measured_unit_measurement_id = jbvju.measured_unit_measurement_id;
                                        unit.port_reference = jbvju.port_reference;
                                        if(!jbvju.measured_measurement){
                                            jbvju.measured_measurement = {
                                                id: null,
                                                width: null,
                                                length: null,
                                                height: null,
                                                weight: null
                                            };
                                        }
                                        for(let bl of booking.bills_of_lading){
                                            let blJobUnit = bl.job_units.find(ju => ju.id == unit.id);
                                            if(blJobUnit){
                                                blJobUnit.is_surveyed = jbvju.is_surveyed;
                                                break;
                                            }
                                        }
                                    });
                                });
                                this.addBLs(booking);
                                let bookingVoyages = this.sendBookingVoyageInfo(booking);
                                booking.bills_of_lading.forEach((bl) => {
                                    if(bl.shipper.phone === null) bl.shipper.phone = "";
                                    if(bl.notify === null){
                                        bl.notify = {
                                            "name": bl.consignee.name,
                                            "email": bl.consignee.email,
                                            "phone": bl.consignee.phone,
                                            "address": bl.consignee.address,
                                            "country_id": bl.consignee.country_id
                                        }
                                    }
                                    if(bl.job_booking_voyage_manifest.length == 0){
                                        bl.job_booking_voyage_manifest = this.hardCopy(bookingVoyages);
                                    }
                                    else{
                                        bl.job_booking_voyage_manifest.forEach((jbvm, jindex) => jbvm.name = bookingVoyages[jindex].name);
                                        bookingVoyages = this.hardCopy(bl.job_booking_voyage_manifest);
                                    }
                                    bl.port_of_loading_name = this.job.port_of_loading.name;
                                    bl.port_of_discharge_name = this.job.port_of_discharge.name;
                                    bl.voyage_name = bl.voyage_name ? bl.voyage_name : (booking.voyages[0] ? booking.voyages[0].vessel.name.toUpperCase() : '');
                                    ['shipper', 'consignee', 'notify'].forEach((type) => {
                                        bl[type].defaultISO = bl[type].country_id ? countries.find(c => c.id == bl[type].country_id).iso : "NL";
                                    });
                                    if(bl.second_notify && bl.second_notify.country_id) bl.second_notify.defaultISO = countries.find(c => c.id == bl.second_notify.country_id).iso;
                                    if(bl.shipper.address && typeof bl.shipper.address == "object"){
                                        bl.shipper.address = bl.shipper.address.street;
                                        bl.consignee.address = bl.consignee.address.street;
                                        bl.notify.address = bl.notify.address.street;
                                    }
                                });
                            });
                            this.$refs.proforma.setQuoteInvoiceLines();
                            if(this.job.invoice){
                                this.$refs.proforma.setInvoiceData();
                            }
                        });
                    }
                    else if(this.job.quote && this.job.quote.quote_routes && this.job.quote.quote_routes[0]){
                        const segments = this.job.quote.quote_routes[0].quote_route_segments;
                        if(segments.length > 1) this.$refs.bookingCreate.preferredVoyageIds.push(segments.map(s => (s.voyage_id+'_'+s.port_of_loading_id+'_'+s.port_of_discharge_id)).join("-") );
                        else this.$refs.bookingCreate.preferredVoyageIds.push(Number(this.job.quote.quote_routes[0].voyage_id));
                    }
                    else if(this.job.voyages){
                        for(let voyage of this.job.voyages){
                            this.$refs.bookingCreate.preferredVoyageIds.push(voyage.id);
                        }
                        delete this.job.voyages;
                    }
                    else if(this.job.status == 'pending'){
                        this.job.pending_booking = {...this.job.bookings.at(-1)};
                        this.job.pending_booking.carrier_reference = '';
                    }
                    this.bookingToOpen = window.tabToOpen !== undefined ? window.tabToOpen : null;
                    this.BLToOpen = window.BLToOpen !== undefined ? window.BLToOpen : null;
                    this.detailToOpen = location.search ? Number(location.search.substring(3)) : null;
                }

                let quoteLink = document.getElementById('quote-link');
                if(quoteLink != null){
                    quoteLink.parentNode.removeChild(quoteLink);
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad) return;
                if(this.job.carriers === undefined) return;

                if(this.job.fileInfo === undefined){
                    this.job.fileInfo = [];
                }

                this.$nextTick(() => {
                    if(this.isEditing){
                        document.getElementById("nextbutton").classList.add('d-none');
                        document.getElementById("confirmorrequest").classList.add('d-none');
                        const modalHeaderText = document.getElementById("exampleModalLabel");
                        modalHeaderText.innerHTML = this.getSubheader();
                        if(this.job.quote && this.job.quote_id){
                            let quoteLink = document.createElement('a');
                            quoteLink.href = './quotations#' + this.job.quote_id;
                            quoteLink.id = 'quote-link';
                            quoteLink.innerHTML = this.$t('navigation.quotations') + ' ' + this.job.quote.number;
                            modalHeaderText.parentElement.insertBefore(quoteLink, modalHeaderText.nextElementSibling);
                        }
                        
                        if(this.bookingToOpen != null){
                            document.getElementById("bldetails-tab").click();
                            this.bookingToOpen = typeof this.bookingToOpen == "string" ? this.job.bookings.findIndex(bk => bk.number == this.bookingToOpen) : this.bookingToOpen;
                            this.selectedBooking = this.bookingToOpen;
                            if(this.BLToOpen != null){
                                const blindex = this.job.bookings[this.bookingToOpen].bills_of_lading.findIndex(bl => bl.number == this.BLToOpen);
                                this.$nextTick(() => {
                                    window.setTimeout(() => {
                                        document.getElementById('a' + blindex + '-tab').click();
                                        this.BLToOpen = null;
                                        if(window.toUnitDetailPage){
                                            delete window.toUnitDetailPage;
                                            this.$nextTick(() => document.getElementById('containers-tab-' + blindex).click());
                                        }
                                    }, 100);
                                });
                            }
                        }
                        else if(this.detailToOpen != null){
                            document.getElementById("details-tab").click();
                            console.log(this.detailToOpen);
                            window.setTimeout(() => {const el = document.getElementById("ub-" + this.detailToOpen); if(el) el.click();}, 100);
                        }
                        else
                            document.getElementById("bookunit-tab").click();

                        this.job.additional_quotes.forEach(quote => {
                            quote.additional_quote_lines.forEach(ql => {
                                ql.add_job_units = ql.job_units.length > 0;
                            });
                            this.aqCount++;
                        })
                    }
                    else if(this.job.status == 'pending'){
                        document.getElementById("nextbutton").classList.add('d-none');
                        document.getElementById("pendingbookingconfirmbutton").classList.remove('d-none');
                        document.getElementById("pendingdownloadbutton").disabled = true;
                        document.getElementById("confirmorrequest").classList.add('d-none');
                        document.getElementById("exampleModalLabel").innerHTML = "Confirm booking";
                    }
                    else{
                        document.getElementById("review-tab").click();
                        document.getElementById("nextbutton").classList.remove('d-none');
                        document.getElementById("confirmorrequest").classList.remove('d-none');
                        this.$refs.bookingCreate.setLicensePlateRequired();
                    }

                    this.$nextTick(() => {
                        //Set message feed
                        if(this.isEditing){
                            this.$refs.jobbooking.reset();
                            if(this.bookingToOpen != null){
                                this.bookingToOpen = null;
                                delete window.tabToOpen;
                            }
                            if(this.detailToOpen != null){
                                window.history.pushState("", "", location.href.replace(/\?q=\d+/, ''));
                            }
                            this.$refs.contactHistory.getMessages(this.job.messages);
                            this.setInvoice = true;
                        }
                        else{
                            window.setTimeout(() => this.$refs.bookingCreate.validateTabOne(), 200);
                        }

                        this.$refs.bookingCreate.setNextButtonDisabled();
                        this.resetBLComponents();
                        this.loaded = true;
                    });
                    let inputArray = [];
                    if(this.job.is_forward){
                        this.job.carriers.forEach((carr) => {
                            if(carr.id === this.job.quote.option_carrier_id){
                                inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": carr.pivot ? carr.pivot.carrier_id : carr.id});
                            }
                        })
                    }
                    else{
                        if(this.job.carriers.length  == 0){
                            inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": null});
                        }
                        else{
                            this.job.carriers.forEach((carr) => {
                                inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": carr.pivot ? carr.pivot.carrier_id : carr.id});
                            });
                        }
                    }
                    
                    this.$refs.bookingCreate.getVoyagesMultiple(inputArray);
                    this.getSurcharges();
                });
            },
            reset(isExistingJob = true) {
                this.isCreatingNew = !isExistingJob;
                this.selectedBooking = 0;
                this.setUnitsModalOpen = false;
                document.querySelector(".modal-body").style.overflowY = "auto";
                document.getElementById("pendingbookingconfirmbutton").classList.add('d-none');
                if(this.isCreatingNew){
                    this.$refs.bookingCreate.reset();
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.bookingToOpen = null;
                    this.job.quote_general_costs = [];
                    this.loadData();
                    return;
                }
                if(this.isCreatingNew){
                    this.isEditing = false;
                    document.getElementById("nextbutton").classList.remove('d-none');
                    document.getElementById("confirmorrequest").classList.remove('d-none');
                    this.job = {
                        additional_quotes: [],
                        bookings: [],
                        customer_id: null,
                        units: [],
                        port_of_loading: {},
                        port_of_discharge: {},
                        port_of_loading_id: null,
                        port_of_discharge_id: null,
                        carriers: this.carriers,
                        receive_stat_mail: false
                    };
                    this.$refs.bookingCreate.loaded = true;
                    this.$nextTick(() => {
                        document.getElementById('customer-tab').click();
                        this.$nextTick(() => {
                            document.querySelectorAll("#customer .simple-typeahead-reset").forEach(el => el.click());
                        });
                    });
                }
            },

            loadData() {
                relationService.index(null,null,'customer').then(response => {
                    this.customers = response.data
                    this.checkData();
                }).catch(error => console.log('error', error))
                portService.index().then(response => {
                    this.ports = response.data
                    this.checkData();
                }).catch(error => console.log('error', error))
                countryservice.indexForBL().then(response => {
                    this.countries = response.data
                    this.checkData();
                }).catch(error => console.log('error', error))
                colourService.index().then(response => {
                    this.colours = response.data
                    this.checkData();
                }).catch(error => console.log('error', error))
                hscodeService.index().then(response => {
                    this.hscodes = response.data
                    this.checkData();
                }).catch(error => console.log('error', error))
                quoteDecriptionService.index().then(response => {
                    this.quoteDescriptions = response.data
                    this.checkData();
                }).catch(error => console.log('error', error))
                this.containerTypes = dataStore.getters.getContainerTypes;
                commodityService.index().then(response => {
                    this.commodities = response.data
                    this.commoditiesById = {};
                    response.data.forEach(comm => this.commoditiesById[comm.id] = comm);
                    this.checkData();
                }).catch(error => console.log('error', error))
                currencyService.indexWithRates().then(response => {
                    this.currencies = response.data;
                    this.currenciesById = {};
                    response.data.forEach(curr => this.currenciesById[curr.id] = curr );
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.$refs.proforma.defaultCurrency = window.defaultCurrency;
                    this.$refs.proforma.tempCurrency = window.defaultCurrency;
                    this.checkData();
                }).catch(error => console.log('error', error))
                relationService.index(null, null, 'supplier').then(response => {
                    this.suppliers = response.data;
                }).catch(error => console.log('error', error))
                relationService.index(null, null, 'surveyor').then(response => {
                    this.surveyers = response.data;
                }).catch(error => console.log('error', error))
            },
            changeVoyageOpen(){
                this.changeVoyageIsOpen = false;
            },
            bulkActionUnits(port, name, value){
                this.changeVoyageIsOpen = false;
                this.$refs.jobbooking.changeVoyage(value);
            },
            
            createBooking(isRequest = false, afterDialog = false){
                if(this.loading){
                    return false;
                }
                if(this.job.pending_booking){
                    this.loading = true;
                    return jobService.setPendingBooking(this.job.id, this.job.pending_booking).then(response => {
                        this.afterBookingCreation(false, response);
                    }).catch(error => console.log('error', error));
                }
                let booking = this.$refs.bookingCreate.getBookingInfo();
                const mode = isRequest ? 'request_action' : 'confirm';
                if(!afterDialog && !window.localStorage.getItem("set_" + mode)){
                    this.$refs.bookingCreate.dialogMode = isRequest ? 'request_action' : 'confirm';
                    document.getElementById("confirm-dialog").showModal();
                    return;
                }
                this.loading = true;
                console.log(booking);
                if(this.isCreatingNew){
                    this.job.voyages = booking.voyages;
                    if(isRequest){
                        this.job.is_request = true;
                    }
                    return jobService.store(this.job).then(response => {
                        this.afterBookingCreation(isRequest, response);
                    }).catch(error => console.log('error', error));
                }
                else{
                    if(isRequest){
                        booking.is_request = true;
                    }
                    return jobService.createBooking(this.job.id, booking).then(response => {
                        this.afterBookingCreation(isRequest, response);
                    }).catch(error => console.log('error', error));
                }
            },
            afterBookingCreation(isRequest, response){
                this.loading = false;
                this.$parent.$parent.getIndex();
                const newBooking = response.data.bookings.at(-1);
                const bookingJobUnits = newBooking.job_unit.map(ju => ju.id).join('-');
                if(isRequest){
                    document.getElementById("modal-close-button").click();
                    jobService.downloadPDF('booking-request', bookingJobUnits).then(rx => this.triggerDownload(rx.data, newBooking.number + "_booking_request.pdf") ).catch(error => this.toastError(error));
                }
                else{
                    this.reset();
                    this.setData(response.data, false);
                    jobService.downloadPDF('combi-zip', bookingJobUnits).then(rx => this.triggerDownload(rx.data, newBooking.number + "_documents.zip") ).catch(error => this.toastError(error));
                }
            },
            proceed(){
                const mode = this.$refs.bookingCreate.dialogMode;
                if(this.$refs.bookingCreate.hasProceedChecked){
                    window.localStorage.setItem("set_" + mode, 1);
                }
                this.createBooking(mode == "request_action", true);
            },

            canCreateBL(){
                return this.job.bookings[this.selectedBooking].pending_units.length > 0 && 
                       this.job.bookings[this.selectedBooking].bills_of_lading.find(bl => bl.number === this.$t('form.new')) === undefined;
            },
            getBookingTabName(booking, longName = false, unitDetailsName = false){
                const voyage = booking.voyages[0] ? booking.voyages[0] : {vessel: {}};
                if(longName){
                    const nameheader = this.$t('operations.booking') + ' ' + booking.number;
                    const vesselheader = voyage.vessel.name + ' ' + voyage.number;
                    if(unitDetailsName){
                        return nameheader + " (" + vesselheader + ")";
                    }
                    return nameheader + ": " + booking.job_unit.length + ' units, ' + vesselheader + ', (' + this.job.carriers.find(carr => carr.id = voyage.carrier_id).relation.name + '), ' + 
                           this.job.port_of_loading.code + ' > ' + this.job.port_of_discharge.code;
                }
                return booking.number + ' (' + booking.job_unit.length + ' units) ' + voyage.number;
            },
            addBLs(booking){
                if(booking.bills_of_lading.length > 0){
                    booking.bills_of_lading.forEach(bl => {
                        this.blCount++;
                        this.blCountWithUnsavedBills++;
                        if(!bl.number)
                            bl.number = this.job.number.replace("J", "BL") + '.' + (this.blCount < 10 ? ('0' + this.blCount) : this.blCount);
                    });
                    return;
                }

                let jobUnits = [];
                this.addNewBL(booking, jobUnits);
                this.blCountWithUnsavedBills++;
            },
            addNewBL(booking, jobUnits = []){
                const newAddress = { "name": "", "email": "", "phone": "", "address": "", "country_id": null };
                const noDefaultShipper = !this.job.customer || this.job.customer.shipper_address === null;
                let newBLLength = booking.bills_of_lading.push({
                    "id": null,
                    "number": this.$t('form.new'),
                    "job_units": jobUnits,
                    "bl_type": "",
                    "payment_option": "",
                    "job_booking_id": booking.id,
                    "shipper": {
                        "name": "",
                        "email": (noDefaultShipper || this.job.customer.shipper_address.email === null) ? '' : this.job.customer.shipper_address.email,
                        "phone": (noDefaultShipper || this.job.customer.shipper_address.phone === null) ? '' : this.job.customer.shipper_address.phone,
                        "address": noDefaultShipper ? '' : this.job.customer.shipper_address.information + "\n" + this.job.customer.shipper_address.street,
                        "country_id": noDefaultShipper ? null : this.job.customer.shipper_address.country_id
                    },
                    "consignee": this.hardCopy(newAddress),
                    "notify": this.hardCopy(newAddress),
                    "notify_same_as_consignee": 0,
                    "has_second_notify": 0,
                    "one_third_per_shipsmail": 0,
                    "collect_clause": 0,
                    "reference": "",
                    "amount_collect": null,
                    "internal_notes": null,
                    "additional_notes": null,
                    "amount_collect_currency_id": window.defaultCurrency,
                    "port_of_loading_id": this.job.port_of_loading_id,
                    "port_of_discharge_id": this.job.port_of_discharge_id,
                    "port_of_loading_name": this.job.port_of_loading.name,
                    "port_of_discharge_name": this.job.port_of_discharge.name,
                    "in_transit": null,
                    "country_id": null,
                    "is_surveyed": null,
                    "surveyor_id": null,
                    "job_unit_containers": [],
                    "job_booking_voyage_manifest": this.sendBookingVoyageInfo(booking),
                    "customer_id": this.job.customer_id,
                    "voyage_id": booking.voyages[0] ? booking.voyages[0].id : null,
                    "port_of_final_destination_id": null,
                    "release_type": null,
                    "is_letterofcredit": 0,
                    "lc_marks_and_numbers": "",
                    "lc_description_of_goods": "",
                    "lc_port_of_loading_name": "",
                    "lc_port_of_discharge_name": "",
                    "lc_port_of_final_destination_name": "",
                    "voyage_name": booking.voyages[0] ? booking.voyages[0].vessel.name.toUpperCase() : '',
                    "city": "",
                    "manifest_office_id": null,
                    "bl_date": null,
                    "office_name": null,
                    "is_collectionfee": 0,
                    "printdimensions": 0,
                    "dimensiondecimals": 3,
                    "additionalclause_id": null,
                    "shipper_name": "",
                    "consignee_name": "",
                    "notify_name": "",
                    "representative_name": "",
                    "local_vessel": null,
                    "local_vessel_port": null,
                    "port_of_final_destination": null,
                    "update_general_details": true,
                    "update_job_units": true,
                    "preload_vessel_id": null,
                    "preload_port_id": null,
                    "preload_departure_date": null,
                    "bill_of_lading_status_id": 1,
                    "issuer": booking.description == 'optional' ? null : 'carrier',
                    "carrier_reference": booking.description == 'optional' ? '' : booking.description
                });

                if(!jobUnits.length){
                    this.$nextTick(() => document.getElementById('a' + (newBLLength - 1) + '-tab').click());
                }
            },
            resetBLComponents(){
                if(this.isEditing){
                    this.isResetting = true;
                    this.$nextTick(() => document.getElementById("a0-tab").click());
                }
            },
            deleteBL(e, booking, index, bl){
                if(!e.target.parentElement.classList.contains('active')) return;
                if(confirm(this.$t('form.are_you_sure_delete') + ' ' + bl.number + '?')){
                    jobService.deleteBL(bl.id).then(deleteResponse => {
                        const prevUnits = [...bl.job_units];
                        prevUnits.forEach(pu => delete pu.bill_of_lading_id);
                        booking.bills_of_lading.splice(index, 1);
                        booking.pending_units = booking.pending_units.concat(prevUnits);
                        if(booking.bills_of_lading.length  == 0){
                            this.addNewBL(booking);
                        }
                        booking.bills_of_lading.push({});
                        booking.bills_of_lading.pop();
                        this.$refs.proforma.setQuoteInvoiceLines();
                    }).catch(error => console.log('error', error));
                }
            },
            sendBookingVoyageInfo(booking){
                let bookingVoyages = [];
                booking.job_booking_voyage.forEach(bv => {
                    let foundVoyage = booking.voyages.find(b => b.id == bv.voyage_id);
                    let pol = bv.port_of_loading ? bv.port_of_loading.code : this.job.port_of_loading.code;
                    let pod = bv.port_of_discharge ? bv.port_of_discharge.code : this.job.port_of_discharge.code;
                    let name = foundVoyage ? (foundVoyage.vessel.name + ' ' + foundVoyage.number + ' ' + pol + ' - ' + pod) : '???';
                    bookingVoyages.push({
                        job_booking_voyage_id: bv.id,
                        is_nmt_bl: null,
                        is_consolidated: false,
                        name: name
                    });
                });
                return bookingVoyages;
            },

            getSubheader(){
                let unitTotal = this.isEditing ? this.unitCount : this.$refs.bookingCreate.unitCount;
                return  (this.isEditing ? this.$t('navigation.jobs') + " " + this.job.number + ": " : this.job.number + ', ') + 
                        (this.job.carriers[0] && this.job.carriers[0].relation ? this.job.carriers[0].relation.name : '?') + (this.job.carriers.length > 1 ? (' +' + (this.job.carriers.length - 1) + ', ') : ', ') +
                        this.job.port_of_loading.code + ' > ' + this.job.port_of_discharge.code + ', ' + unitTotal + ' ' + this.$tc('operations.n_units_total', unitTotal) +
                        ((this.job.quote != null && !this.isEditing) ? (' (' + this.$t('overview.from') + ' ' + this.job.quote.number + ')') : '');
            },
            getSurcharges(){
                let carriers = [];
                if(this.isEditing){
                    this.job.carriers.forEach(carr => {
                        carriers.push(carr.carrier_id);
                    })
                }
                else{
                    carriers = this.carrier_ids;
                }
                let pol = this.job.port_of_loading.id;
                let pod = this.job.port_of_discharge.id;
                surchargeService.search(carriers, [pol], pod, this.job.created_at).then(response => {
                    this.surcharges = response.data
                }).catch(error => console.log('error', error));
            },

            async downloadExcel(args) {
                const type = args[0], value = args[1], name = args[2];
                return jobService.downloadExcel(type, value).then(response => {
                    this.triggerDownload(response.data, name + "_ISF.xlsx");
                }).catch(error => this.toastError(error) );

            },
            async save(args) {
                const bl = args[0], index = args[1], ref = args[2], download = args[3];
                const is_final = bl.bill_of_lading_status_id == 3;
                const blDoc = document.getElementById("b"+index);
                //Validation - Tab 2
                if(!bl.payment_option || !bl.bl_type || !bl.release_type){
                    alert(this.$t('errors.bl'));
                    blDoc.querySelector("#generaldets-tab-"+index).click();
                    return false;
                }
                if(ref.hasSurveyedUnits(bl) && !bl.surveyor_id){
                    alert(this.$t('errors.bl_2'));
                    blDoc.querySelector("#containers-tab-"+index).click();
                    return false;
                }
                if(bl.in_transit && !bl.country_id){
                    alert(this.$t('errors.bl_3'));
                    blDoc.querySelector("#generaldets-tab-"+index).click();
                    return false;
                }
                const shippername = bl.shipper_name ? bl.shipper_name.toUpperCase() : bl.shipper.address.toUpperCase();
                if(!bl.shipper_name){
                    bl.shipper_name = shippername;
                }
                if(is_final && bl.bl_type == 'Original'){
                    const consigneename = bl.consignee_name ? bl.consignee_name.toUpperCase() : bl.consignee.address.toUpperCase();
                    if(shippername == "TO ORDER" || consigneename == "TO ORDER"){
                        alert(this.$t('errors.bl_5'));
                        return false;
                    }
                }
                const requiredQuery = is_final ? "#iteniary input:not([type='checkbox'])" : ("#iteniary .col-6.position-relative input:not([type='checkbox']), #iteniary .col-6.position-relative .vue-tel-input");
                let isValid = true;
                let required = blDoc.querySelectorAll(requiredQuery);
                for(let field of required){
                    let value = field.value;
                    if(field.tagName == "DIV"){
                        value = field.children[1].value;
                    }
                    if((field.required || field.classList.contains('required')) && !value){
                        field.classList.add('is-invalid');
                        isValid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                    blDoc.querySelector("#generaldets-tab-"+index).click();
                    return false;
                }
                //Tab 3
                if(bl.job_units.length == 0){
                    alert(this.$t('errors.bl_3'));
                    blDoc.querySelector("#containers-tab-"+index).click();
                    return false;
                }
                let invalidUnitTab = 0;
                for(let uindex in bl.job_units){
                    const unit = bl.job_units[uindex];
                    invalidUnitTab = uindex;
                    isValid = unit.chassis_number && unit.weight && unit.volume;
                    if(ref.makeModelRequired && (!unit.unit_make_id || !unit.unit_model_id)) isValid = false;
                    else if(ref.buildYearRequired && !unit.construction_year)                isValid = false;
                    else if(ref.colourRequired && !unit.colour_id)                           isValid = false;
                    else if(ref.hsCodeRequired && !unit.hs_code)                             isValid = false;
                    else if(ref.licensePlateRequired && !unit.license_plate)                 isValid = false;

                    if(!isValid){
                        break;
                    }
                }
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                    blDoc.getElementById("containers-tab-"+index).click();
                    this.$nextTick(() => {
                        blDoc.getElementById('uli-'+bl.id+'-'+invalidUnitTab).click();
                        window.setTimeout(() => {
                            const req = blDoc.querySelectorAll('#containers .unit-details-section input[required], #containers .unit-details-section select[required]');
                            req.forEach(el => {
                                if(!el.value || el.value == this.$t('form.select')){
                                    el.classList.add('is-invalid');
                                }
                            })
                        }, 500);
                    });
                    return false;
                }

                bl.update_general_details = true;
                bl.update_job_units = true;
                if(!bl.id){
                    return billofladingService.store(bl).then((response) => {
                        bl.id = response.data.id;
                        bl.number = response.data.number;
                        if(download) return jobService.downloadPDF('bill-of-lading', bl.id).then(rx => this.triggerDownload(rx.data, bl.number + ".pdf") ).catch(error => this.toastError(error));
                        else this.$toast.success(this.$t('form.created_x', [this.$t('navigation.bills_of_lading')]));
                    });
                }
                else{
                    return billofladingService.update(bl).then((response) => {
                        if(download) return jobService.downloadPDF('bill-of-lading', bl.id).then(rx => this.triggerDownload(rx.data, bl.number + ".pdf") ).catch(error => this.toastError(error));
                        else this.$toast.success(this.$t('form.updated_x', [this.$t('navigation.bills_of_lading')]));
                    });
                }
            },
            
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },
            addFiles(fileInfo){
                fileService.upload({"file_info": fileInfo, "id": this.job.id, "model": 'job'}).then(response => {
                    this.job.file_info.unshift(response.data); 
                }).catch(error => console.log('error', error));
            },

            checkData(){
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isCreatingNew) this.reset(false);
                    else this.setData(false);
                }
            }

        },
        mounted() {
            document.addEventListener('dragover', function(e) {
                e.preventDefault();
                if (window.dragShadow) {
                    window.dragShadow.style.left = (e.clientX - 11) + 'px';
                    window.dragShadow.style.top  = (e.clientY + 18) + 'px';
                }
            }, true);
            for(let i = 1; i <= 100; i++) this.quantityOptions.push({id: i, name: i});
            this.conditionOptions = dataStore.getters.getConditions;
        }
    }
</script>

<style scoped>
    .badge {
        background-color: #aaa;
        color: black;
        border-radius: 10em
    }
    .nav-link-alt { 
        background: #fff;
        border: 1px solid black;
        border-right: none !important;
        border-radius: 6px 0 0 6px;
        padding: 3px 4px;
        width: 160px;
        text-align: end
    }
    .nav-link-alt:hover { 
        background: #eee;
    }
    .nav-link-alt.active { 
        background: var(--bs-primary) !important;
        color: #fff;
    }
</style>
<style>
    #job-popup .modal-title{
        width: 85%
    }
    #job-popup .p-0 .nav-link{
        padding: 0 !important;
    }
    #job-popup .p-0 li.nav-item, .p-0 li.nav-link{
        height: 32px;
    }
    #job-popup .col-0p5{
        width: 3% !important;
        padding-left: 1px !important;
        padding-right: 1px !important
    }
    #jobTabContent > div:not(#invoices) .col-2{  width: 15% !important }
    .add-unit-button{ width: 128px }
    .bl-personal-details .row{  margin-bottom: 8px }
    .bl-personal-details .flex-row-reverse{  margin-left: 2px }
    .bl-personal-details > .row > div{ padding: 0 !important }
    .bl-personal-details .row > label:not(:first-child){
        display: block;
        width: 20%;
        padding: 4px 0 0 16px
    }
    .margin-amount{
        font-size: 0.75rem;
        padding: 0.3rem 0.4rem
    }
    #job-popup .col-sm-xs select{
        padding-left: 0.1rem;
        padding-right: 1rem;
        font-size: .75rem;
        background-position: 92.5%;
        background-size: 10px;
        height: 26px
    }
    #myTabContent2{
        min-height: calc(90vh - 264px) !important
    }
</style>